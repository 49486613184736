import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../../configs/primereact';
import { IntBool } from '../../../../enums/boolean';
import { emptyCell } from '../../../../utils/constants/tables';
import { dateTimeCell } from '../../../../utils/helpers/dataTable';
import { currencyFormat } from '../../../../utils/helpers/formatting';
import { formatPhoneOrMobileNumber } from '../../../../utils/helpers/phoneNumbers';

export type RouteParams = {
  id?: string;
};

export type SingleWarrant = {
  seriski: string;
};

export type CODParcels = {
  id: number;
  seriski_broj: string;
  barkod: string;
  reference1: string;
  reference2: string;
  klient_naracatel_id: null;
  klient_od_id: null;
  klient_od_ime: string;
  klient_do_id: null;
  klient_do_ime: string;
  adresa_od: string;
  adresa_do: string;
  telefon_od: string;
  telefon_do: string;
  mobilen_od: string;
  mobilen_do: string;
  otkup: string;
  cena: null;
  vrednost: null;
  mesto_od_id: number;
  mesto_od_ime: string;
  opstina_od_ime: string | null;
  ulica_od_id: number | null;
  ulica_do_id: number | null;
  mesto_do_id: number;
  mesto_do_ime: string;
  opstina_do_ime: string | null;
  broj_od: string | null;
  broj_do: string | null;
  vlez_od: string | null;
  vlez_do: string | null;
  stan_od: string | null;
  stan_do: string | null;
  datum_kreiranje: string;
  datum_isporaka: string;
  datum_priem: string;
  datum_posledna_izmena: string | null;
  terminsko_vreme_do: string | null;
  terminsko_vreme_od: string | null;
  vremenska_ramka_id: number | null;
  povraten_dokument: string | null;
  komentar: string | null;
  status_id: '80';
  proizvod_id: number | null;
  proizvod_ime: string | null;
  personal_delivery: IntBool | null;
  specijaliziran_proizvod_id: number | null;
  tezina: string | null;
  volumen: string | null;
  sirina: string | null;
  visina: string | null;
  dolzina: string | null;
  location_from: string | null;
  location_to: string | null;
  status_name: string | null;
  postar_od_name: string | null;
  postar_do_name: string | null;
  type_of_delivery: string | null;
  payments: string | null;
  hub_od_id: number | null;
  hub_do_id: number | null;
};

export const tableStorageKey = 'viewOrders_datatable';

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Pick<
      CODParcels,
      | 'seriski_broj'
      | 'reference1'
      | 'datum_kreiranje'
      | 'datum_priem'
      | 'datum_isporaka'
      | 'klient_od_ime'
      | 'adresa_od'
      | 'mesto_od_ime'
      | 'telefon_od'
      | 'mobilen_od'
      | 'klient_do_ime'
      | 'adresa_do'
      | 'mesto_do_ime'
      | 'telefon_do'
      | 'mobilen_do'
      | 'otkup'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    seriski_broj: t('Serial No.'),
    reference1: t('Reference'),
    datum_kreiranje: t('Created on'),
    datum_priem: t('Received on'),
    datum_isporaka: t('Shipped on'),
    klient_od_ime: t('Client from'),
    adresa_od: t('Address from'),
    mesto_od_ime: t('Place from'),
    telefon_od: t('Phone from'),
    mobilen_od: t('Mobile from'),
    klient_do_ime: t('Client to'),
    adresa_do: t('Address to'),
    mesto_do_ime: t('Place to'),
    telefon_do: t('Phone to'),
    mobilen_do: t('Mobile to'),
    otkup: t('Redemption'),
  };
}

export function additionalColumnOptions(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'seriski_broj':
    case 'reference1':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'datum_kreiranje':
    case 'datum_isporaka':
    case 'datum_priem':
      return {
        body: (row: CODParcels) => dateTimeCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'klient_od_ime':
    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'adresa_od':
    case 'adresa_do':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'mesto_od_ime':
    case 'mesto_do_ime':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'telefon_od':
    case 'telefon_do':
    case 'mobilen_od':
    case 'mobilen_do':
      return {
        body: (row: CODParcels) =>
          row[column]?.trim()
            ? formatPhoneOrMobileNumber(row[column] as string)
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'otkup':
      return {
        body: (row: CODParcels) =>
          row[column]
            ? currencyFormat(row[column] as string, { showCurrency: true })
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}
