import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../configs/orders';
import { OrderStatus } from '../../../enums/orders';
import { OrderCollection, OrderResource } from '../../../types/api/orders';
import { Numeric } from '../../../types/general';
import { emptyCell } from '../../../utils/constants/tables';
import { dateTimeCell, weightCell } from '../../../utils/helpers/dataTable';
import {
  currencyFormat,
  trailingEllipsisFormat,
} from '../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../utils/helpers/primereact';
import ActionsTableMenu from '../../Components/ActionsTableMenu/ActionsTableMenu';

export type Status = {
  id: Numeric;
  name: string;
  total: Numeric;
};

export const tableStorageKey = 'shipments_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    seriski_broj: t('Serial No.'),
    status_id: t('Status'),
    datum_kreiranje: t('Creation Date'),
    datum_priem: t('Reception Date'),
    datum_isporaka: t('Delivery Date'),
    reference1: t('Reference No. 1'),
    reference2: t('Reference No. 2'),
    klient_od_ime: t('Sender Name'),
    adresa_od: t('Sender Address'),
    klient_do_ime: t('Receiver Name'),
    adresa_do: t('Receiver Address'),
    kolicina: t('Amount'),
    tezina: t('Weight'),
    otkup: t('Redemption'),
    cena: t('Price'),
    komentar: t('Comment'),
  };
}

function getIcon(status_id: OrderStatus, status_name: string) {
  const color =
    getOrderStatusConfig()[status_id]?.color ?? 'var(--bluegray-200)';

  return (
    <span data-tip={status_name} data-for="tooltip-right">
      <FontAwesomeIcon icon={faCircle} style={{ color }} />
    </span>
  );
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  handleCMRecreateClick: (caller: string) => void,
  handleCMCancelClick: () => void,
  setAction: (p: string) => void
) {
  switch (column) {
    case 'seriski_broj':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'status_id':
      return {
        body: (row: OrderResource & { time_from_pickup: string }) => {
          const statusIcon = getIcon(row[column], row['status_name']);
          return <span>{statusIcon}</span>;
        },
        style: {
          width: 100,
          flex: '0 0 auto',
          justifyContent: 'center',
        },
        bodyStyle: {
          width: 100,
          flex: '0 0 auto',
          justifyContent: 'center',
        },
      };

    case 'reference1':
    case 'reference2':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },

        style: {
          width: 150,
        },
        bodyStyle: {
          width: 150,
        },
      };

    case 'datum_kreiranje':
    case 'datum_priem':
    case 'datum_isporaka':
      return {
        body: (row: OrderResource) => {
          const dateFormatted = dateTimeCell(row[column] ?? '').toString();

          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(dateFormatted!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_od_ime':
    case 'adresa_od':
    case 'klient_do_ime':
    case 'adresa_do':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'kolicina':
      return {
        body: (row: OrderResource) => {
          return <span>{Intl.NumberFormat().format(Number(row[column]))}</span>;
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'tezina':
      return {
        body: (row: OrderResource) => {
          return weightCell(t, row[column]);
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'otkup':
      return {
        body: (row: OrderResource) => {
          return (
            <span>
              {currencyFormat(row[column] ?? '', { showCurrency: true })}
            </span>
          );
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'cena':
      return {
        body: (row: OrderResource) => {
          return (
            <span>
              {currencyFormat(row[column] ?? '', { showCurrency: true })}
            </span>
          );
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'komentar':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 280,
        },
        bodyStyle: {
          width: 280,
        },
      };

    case 'actions':
      return {
        body: (row: OrderResource) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View details'),
                  icon: 'fas fa-file-alt',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                  },
                },
                {
                  label: t('Edit order'),
                  icon: 'fas fa-edit',
                  disabled: row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                  },
                },
                {
                  label: t('Create a similar order'),
                  icon: 'fas fa-plus-square',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    handleCMRecreateClick('context-menu');
                  },
                },
                {
                  label: t('Status tracking'),
                  icon: 'fas fa-search-location',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('track');
                  },
                },
                {
                  label: t('Print address document'),
                  icon: 'fas fa-address-book',
                  disabled: row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-address');
                  },
                },
                {
                  label: t('Print sticker'),
                  icon: 'fas fa-ticket-alt',
                  disabled: row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-sticker');
                  },
                },
                {
                  label: t('Print pickup specification'),
                  icon: 'fas fa-ticket-alt',
                  disabled: row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-specification');
                  },
                },
                {
                  label: t('Cancel order'),
                  icon: 'fas fa-trash',
                  disabled: row.status_id >= OrderStatus.Cancelled,
                  command: () => {
                    setContextMenuSelection(row);
                    handleCMCancelClick();
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}

export function getMobileColumnHeadersMap(t: TFunction) {
  return {
    seriski_broj: t('Serial No.'),
    status_name: t('Status'),
    dokument_broj: t('Document No.'),
    klient_od_ime: t('Sender'),
    klient_do_ime: t('Receiver'),
  };
}

export function mobileAdditionalColumnProperties(
  column: keyof ReturnType<typeof getMobileColumnHeadersMap>
) {
  switch (column) {
    case 'seriski_broj':
    case 'status_name':
    case 'dokument_broj':
    case 'klient_od_ime':
    case 'klient_do_ime':
      return {
        style: {
          width: 'auto',
        },
        bodyStyle: {
          width: 'auto',
        },
      };
    default:
      return {};
  }
}

export function generateGroupActions(
  t: TFunction,
  selectionMultiple: OrderCollection['data'] | undefined,
  handlePrintAddressGroup: () => void,
  handlePrintSpecification: (caller: string) => void,
  handlePrintStickerGroup: () => void,
  handleCMCancelClick: () => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>
) {
  const deletableShipments = selectionMultiple?.filter(
    (o) => o.status_id < OrderStatus.Cancelled
  );

  const printGroupAddressesOption = {
    label: t('Print addresses group'),
    icon: 'fas fa-address-book',
    command: () => {
      handlePrintAddressGroup();
    },
    disabled: deletableShipments?.length !== selectionMultiple?.length,
  };

  const printMultipleStickersOption = {
    label: t('Print stickers'),
    icon: 'fas fa-ticket-alt',
    command: () => {
      handlePrintStickerGroup();
    },
    disabled: deletableShipments?.length !== selectionMultiple?.length,
  };

  const printGroupPickupSpecOption = {
    label: t('Print pickup specification'),
    icon: 'fas fa-clipboard-list',
    command: () => {
      handlePrintSpecification('group-actions');
    },
    disabled: deletableShipments?.length !== selectionMultiple?.length,
  };

  const groupDeleteOption = {
    label: t('Cancel group orders'),
    icon: 'fas fa-trash',
    command: () => {
      setCaller('group-actions');
      handleCMCancelClick();
    },
  };

  return contextMenuModel([
    [
      [true, printGroupAddressesOption],
      [true, printGroupPickupSpecOption],
      [true, printMultipleStickersOption],
    ],
    [[!!deletableShipments?.length, groupDeleteOption]],
  ]);
}
