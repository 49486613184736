import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _, { isBoolean } from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { MyRole } from '../../../enums/orders';
import useAxios from '../../../hooks/useAxios';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { WithData } from '../../../types/api';
import { ClientSubsidiaryCollection } from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import { TableProps } from '../../DataTable/Table/Table';
import {
  getOrderPackagingOptions,
  getOrderReturnDocumentOptions,
  getPaymentStatusOptions,
} from './CreateEditRecreate/CreateEditRecreate.functions';
import { Status } from './Orders.functions';

function useTableFilters(
  page: number,
  setPage: TableProps['setPage'],
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().toDate()
  );

  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? dayjs().toDate()
  );

  const [pickupDateFrom, setPickupDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_from') ?? ''
      ) ?? null
  );

  const [pickupDateTo, setPickupDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_to') ?? ''
      ) ?? null
  );

  const [deliveryDateFrom, setDeliveryDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_from') ?? ''
      ) ?? null
  );

  const [deliveryDateTo, setDeliveryDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_to') ?? ''
      ) ?? null
  );

  const [clientOrdererName, setClientOrdererName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_orderer_name') ?? ''
  );

  const [serial, setSerial] = useState<string>(
    () => getSearchQueryParam(location.search, 'serial') ?? ''
  );

  const [clientFromName, setClientFromName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_from_name') ?? ''
  );

  const [reference, setReference] = useState<string>(
    () => getSearchQueryParam(location.search, 'reference1') ?? ''
  );

  const [clientToName, setClientToName] = useState<string>(
    () => getSearchQueryParam(location.search, 'client_to_name') ?? ''
  );

  const [status, setStatus] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'status') ?? null
  );

  const [returnDocument, setReturnDocument] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'return_document') ?? null
  );

  const [packagingId, setPackagingId] = useState<string | null>(
    () =>
      getSearchQueryParam(location.search, 'adresnica_service_packaging_id') ??
      null
  );

  const [roles, setRoles] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'roles') ?? null
  );

  const [codPaymentStatus, setCodPaymentStatus] = useState<string | null>(
    () => getSearchQueryParam(location.search, 'cod_payment_status') ?? null
  );

  const [fragile, setFragile] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'fragile') ?? null
  );

  const [twoManDelivery, setTwoManDelivery] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'two_man_delivery') ?? null
  );

  const [phoneFrom, setPhoneFrom] = useState<string | null>(
    getSearchQueryParam(location.search, 'phone_from') ?? null
  );

  const [phoneTo, setPhoneTo] = useState<string>(
    () => getSearchQueryParam(location.search, 'phone_to') ?? ''
  );

  const [branch, setBranch] = useState<boolean | any>(
    () => getSearchQueryParam(location.search, 'branch') ?? null
  );

  const { data: subsidiaries } = useAxios<ClientSubsidiaryCollection>({
    url: `/clients/${clientLoggedIn}/subsidiaries`,
  });

  useEffect(() => {
    if (!setPage) {
      return;
    }
    setPage(1);
  }, [dateFrom, dateTo, setPage]);

  const partyTypeOptions = useMemo(() => {
    return [
      { label: t('Any'), value: 'any' },
      { label: t('Sender'), value: 'sender' },
      { label: t('Receiver'), value: 'receiver' },
      { label: t('Orderer'), value: 'orderer' },
    ];
  }, [t]);

  const rolesOptions = useMemo(() => {
    return [
      { label: t('Any'), value: MyRole.Any },
      { label: t('Sender'), value: MyRole.Sender },
      { label: t('Receiver'), value: MyRole.Receiver },
      { label: t('Orderer'), value: MyRole.Orderer },
    ];
  }, [t]);

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setPickupDateFrom(null);
    setPickupDateTo(null);
    setDeliveryDateFrom(null);
    setDeliveryDateTo(null);
    setClientOrdererName('');
    setSerial('');
    setClientFromName('');
    setReference('');
    setClientToName('');
    setStatus('');
    setReturnDocument(null);
    setPackagingId(null);
    setRoles('');
    setCodPaymentStatus('');
    setFragile(null);
    setTwoManDelivery(null);
    setPhoneFrom('');
    setPhoneTo('');
    setBranch(null);
  }

  const orderPackagingOptions = useMemo(
    () =>
      getOrderPackagingOptions(t).map((packingOptions) => ({
        label: packingOptions.label,
        value: String(packingOptions.value),
      })) ?? [],
    [t]
  );
  const paymentStatusOptions = useMemo(
    () =>
      getPaymentStatusOptions(t).map((paymentStatus) => ({
        label: paymentStatus.label,
        value: String(paymentStatus.value),
      })) ?? [],
    [t]
  );
  const orderReturnDocumentOptions = useMemo(
    () =>
      getOrderReturnDocumentOptions(t).map((typeDocument) => ({
        label: typeDocument.label,
        value: String(typeDocument.value),
      })) ?? [],
    [t]
  );

  const debouncedClientOrdererName = useDebounce(
    clientOrdererName,
    debounceTimeout
  );
  const debouncedClientFromName = useDebounce(clientFromName, debounceTimeout);
  const debouncedClientToName = useDebounce(clientToName, debounceTimeout);
  const debouncedReference = useDebounce(reference, debounceTimeout);
  const debouncedSerial = useDebounce(serial, debounceTimeout);
  const debouncedPhoneFrom = useDebounce(phoneFrom, debounceTimeout);
  const debouncedPhoneTo = useDebounce(phoneTo, debounceTimeout);

  const filtersArr = useMemo(
    () => [
      dateFrom,
      dateTo,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      debouncedClientOrdererName,
      debouncedSerial,
      debouncedClientFromName,
      debouncedReference,
      debouncedClientToName,
      status,
      returnDocument,
      packagingId,
      roles,
      codPaymentStatus,
      fragile,
      twoManDelivery,
      debouncedPhoneFrom,
      debouncedPhoneTo,
      branch,
    ],
    [
      dateFrom,
      dateTo,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      debouncedClientOrdererName,
      debouncedSerial,
      debouncedClientFromName,
      debouncedReference,
      debouncedClientToName,
      status,
      returnDocument,
      packagingId,
      roles,
      codPaymentStatus,
      fragile,
      twoManDelivery,
      debouncedPhoneFrom,
      debouncedPhoneTo,
      branch,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(() => {
    const _dateFrom = dayjs(dateFrom);
    const _dateTo = dayjs(dateTo);
    const _pickupDateFrom = dayjs(pickupDateFrom);
    const _pickupDateTo = dayjs(pickupDateTo);
    const _deliveryDateFrom = dayjs(deliveryDateFrom);
    const _deliveryDateTo = dayjs(deliveryDateTo);

    return {
      date_from: httpDateFormat(_dateFrom),
      date_to: httpDateFormat(_dateTo),
      pickup_date_from: httpDateFormat(_pickupDateFrom),
      pickup_date_to: httpDateFormat(_pickupDateTo),
      delivery_date_from: httpDateFormat(_deliveryDateFrom),
      delivery_date_to: httpDateFormat(_deliveryDateTo),
      client_orderer_name: debouncedClientOrdererName,
      serial: debouncedSerial,
      client_from_name: debouncedClientFromName,
      reference1: debouncedReference,
      client_to_name: debouncedClientToName,
      status: status,
      return_document: returnDocument,
      adresnica_service_packaging_id: packagingId,
      roles: roles,
      cod_payment_status: codPaymentStatus,
      fragile: fragile ? '1' : null,
      two_man_delivery: twoManDelivery ? '1' : null,
      phone_from: debouncedPhoneFrom,
      phone_to: debouncedPhoneTo,
      branch: branch ? '1' : null,
      page: haveFiltersChanged ? 1 : page,
      limit,
      summary: 1,
    };
  }, [
    dateFrom,
    dateTo,
    pickupDateFrom,
    pickupDateTo,
    deliveryDateFrom,
    deliveryDateTo,
    debouncedClientOrdererName,
    debouncedSerial,
    debouncedClientFromName,
    debouncedReference,
    debouncedClientToName,
    status,
    returnDocument,
    packagingId,
    roles,
    codPaymentStatus,
    fragile,
    twoManDelivery,
    debouncedPhoneFrom,
    debouncedPhoneTo,
    branch,
    haveFiltersChanged,
    page,
    limit,
  ]);

  const {
    data: statusesData,
    isLoading: isLoadingStatuses,
    error: errorStatuses,
  } = useAxios<WithData<Status[]>>(
    { url: '/orders/statuses' + queryString(_.omit(httpFiltersObj, 'status')) },
    {
      skipWhen:
        !httpFiltersObj.date_to || !dayjs(httpFiltersObj.date_to).isValid(),
    }
  );

  const statusesOptions = useMemo<{ label: string; value: string }[]>(
    () =>
      statusesData?.data?.map((status: Status) => ({
        label: `${status.name} [${status.total}]`,
        value: String(status.id),
      })) ?? [],
    [statusesData]
  );

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryDateParam('pickup_date_from', pickupDateFrom);
  useSearchQueryDateParam('pickup_date_to', pickupDateTo);
  useSearchQueryDateParam('delivery_date_from', deliveryDateFrom);
  useSearchQueryParam('client_orderer_name', clientOrdererName);
  useSearchQueryParam('serial', debouncedSerial);
  useSearchQueryParam('client_from_name', clientFromName);
  useSearchQueryParam('referenceNo1', debouncedReference);
  useSearchQueryParam('client_to_name', clientToName);
  useSearchQueryDropdownParam('status', status, setStatus, statusesOptions);
  useSearchQueryDropdownParam(
    'return_document',
    returnDocument,
    setReturnDocument,
    orderReturnDocumentOptions
  );
  useSearchQueryDropdownParam(
    'adresnica_service_packaging_id',
    packagingId,
    setPackagingId,
    orderPackagingOptions
  );
  useSearchQueryDropdownParam('roles', roles, setRoles, partyTypeOptions);
  useSearchQueryDropdownParam(
    'cod_payment_status',
    codPaymentStatus,
    setCodPaymentStatus,
    paymentStatusOptions
  );
  useSearchQueryParam('fragile', fragile);
  useSearchQueryParam('two_man_delivery', twoManDelivery);
  useSearchQueryParam('phone_from', debouncedPhoneFrom);
  useSearchQueryParam('phone_to', debouncedPhoneTo);
  useSearchQueryParam('branch', branch);

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom, // date_from and date_to is count like one
    pickupDateFrom, // pickup_date_from and date_to is count like one
    deliveryDateFrom, // delivery_date_from and date_to is count like one
    clientOrdererName,
    serial,
    clientFromName,
    reference, // reference1 and reference2 values we send here
    clientToName,
    status,
  }).filter(Boolean).length;

  const orderAttributesFiltersActiveFilterCount = Object.values({
    returnDocument,
    packagingId,
    roles,
    codPaymentStatus,
    fragile,
    twoManDelivery,
  }).filter(Boolean).length;

  const additionalFiltersActiveFilterCount = Object.values({
    phoneFrom,
    phoneTo,
    branch,
  }).filter(Boolean).length;

  const basicFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Basic filters')}</span>
        {basicFiltersActiveFilterCount > 0 && (
          <span className="badge">{basicFiltersActiveFilterCount}</span>
        )}
      </div>
    ),
    [t, basicFiltersActiveFilterCount]
  );

  const orderAttributesFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Attributes')}</span>
        {orderAttributesFiltersActiveFilterCount > 0 && (
          <span className="badge">
            {orderAttributesFiltersActiveFilterCount}
          </span>
        )}
      </div>
    ),
    [t, orderAttributesFiltersActiveFilterCount]
  );

  const additionalFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Additional Filters')}</span>
        {additionalFiltersActiveFilterCount > 0 && (
          <span className="badge">{additionalFiltersActiveFilterCount}</span>
        )}
      </div>
    ),
    [t, additionalFiltersActiveFilterCount]
  );

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_date_from"
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                dateFormat="dd/mm/yy"
                yearRange={`2009:${dayjs().format('YYYY')}`}
                maxDate={new Date()}
                onChange={(e) => setDateFrom(e.value as Date | null)}
                inputClassName={classNames({
                  sidebar_filter_active: dateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_date_to"
                value={dateTo ?? undefined}
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: dateTo,
                })}
              />
            </div>
          </div>

          <div className="sidebar_filter">
            <label htmlFor="filter_pickup_date_from">
              {t('Received Date')}
            </label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_pickup_date_from"
                value={pickupDateFrom ?? undefined}
                onChange={(e) => {
                  setPickupDateFrom(e.value as Date | null);
                  if (e.value) {
                    setPickupDateTo(new Date());
                  }
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: pickupDateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_pickup_date_to"
                value={pickupDateTo ?? undefined}
                onChange={(e) => setPickupDateTo(e.value as Date | null)}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: pickupDateTo,
                })}
              />
            </div>
          </div>

          <div className="sidebar_filter">
            <label htmlFor="filter_delivery_date_from">
              {t('Shipping Date')}
            </label>
            <div className="sidebar_filter_row">
              <Calendar
                id="filter_delivery_date_from"
                value={deliveryDateFrom ?? undefined}
                onChange={(e) => {
                  setDeliveryDateFrom(e.value as Date | null);
                  if (e.value) {
                    setDeliveryDateTo(new Date());
                  }
                }}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: deliveryDateFrom,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                id="filter_delivery_date_to"
                value={deliveryDateTo ?? undefined}
                onChange={(e) => setDeliveryDateTo(e.value as Date | null)}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                inputClassName={classNames({
                  sidebar_filter_active: deliveryDateTo,
                })}
              />
            </div>
          </div>

          <div className="sidebar_filter">
            <label htmlFor="serial_filter">{t('Serial No')}</label>
            <InputText
              id="serial_filter"
              value={serial ?? ''}
              onChange={(e) => setSerial(e.target.value)}
              className={classNames({
                sidebar_filter_active: serial,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="client_orderer_name_filter">{t('Orderer')}</label>
            <InputText
              id="client_orderer_name_filter"
              value={clientOrdererName ?? ''}
              onChange={(e) => setClientOrdererName(e.target.value)}
              className={classNames({
                sidebar_filter_active: clientOrdererName,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="client_from_name_filter">{t('Sender')}</label>
            <InputText
              id="client_from_name_filter"
              value={clientFromName ?? ''}
              onChange={(e) => setClientFromName(e.target.value)}
              className={classNames({
                sidebar_filter_active: clientFromName,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="client_to_name_filter">{t('Recipient')}</label>
            <InputText
              id="client_to_name_filter"
              value={clientToName ?? ''}
              onChange={(e) => setClientToName(e.target.value)}
              className={classNames({
                sidebar_filter_active: clientToName,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="status_filter">{t('Status')}</label>
            <Dropdown
              id="status_filter"
              disabled={isLoadingStatuses ?? errorStatuses}
              onChange={(e: DropdownChangeParams) => setStatus(e.value)}
              options={statusesOptions}
              value={status}
              showClear
              filter
              className={classNames({
                sidebar_filter_active: status,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="reference_filter">{t('Reference')}</label>
            <InputText
              id="reference_filter"
              onChange={(e) => setReference(e.target.value)}
              value={reference ?? ''}
              className={classNames({
                sidebar_filter_active: reference,
              })}
            />
          </div>
        </AccordionTab>
        <AccordionTab headerTemplate={orderAttributesFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="return_document_filter">
              {t('Return document')}
            </label>
            <Dropdown
              id="return_document_filter"
              options={orderReturnDocumentOptions}
              onChange={(e: DropdownChangeParams) => setReturnDocument(e.value)}
              value={returnDocument}
              showClear
              className={classNames({
                sidebar_filter_active: returnDocument,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="adresnica_service_packaging_id_filter">
              {t('Order service packaging')}
            </label>
            <Dropdown
              id="adresnica_service_packaging_id_filter"
              options={orderPackagingOptions}
              onChange={(e: DropdownChangeParams) => setPackagingId(e.value)}
              value={packagingId}
              showClear
              placeholder={t('No packaging')}
              className={classNames({
                sidebar_filter_active: packagingId,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="roles_filter">{t('My Role')}</label>
            <Dropdown
              id="roles_filter"
              disabled={isLoadingStatuses ?? errorStatuses}
              options={rolesOptions}
              onChange={(e: DropdownChangeParams) => setRoles(e.value)}
              value={roles}
              filter
              className={classNames({
                sidebar_filter_active: roles,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="cod_payment_status_filter">
              {t('Payment Status')}
            </label>
            <Dropdown
              id="cod_payment_status_filter"
              options={paymentStatusOptions}
              onChange={(e: DropdownChangeParams) =>
                setCodPaymentStatus(e.value)
              }
              value={codPaymentStatus}
              showClear
              placeholder={t('All')}
              className={classNames({
                sidebar_filter_active: codPaymentStatus,
              })}
            />
          </div>

          <div className="sidebar_filter_same_row">
            <div className="sidebar_filter">
              <label htmlFor="fragile_filter">{t('Fragile')}</label>
              <InputSwitch
                id="fragile_filter"
                checked={isBoolean(fragile) ? fragile : undefined}
                onChange={(e: InputSwitchChangeParams) =>
                  setFragile(Boolean(e.value))
                }
                className={classNames({
                  sidebar_filter_active: fragile,
                })}
              />
            </div>
            <div className="sidebar_filter">
              <label htmlFor="two_man_delivery_filter">
                {t('Two man delivery')}
              </label>
              <InputSwitch
                id="two_man_delivery_filter"
                checked={isBoolean(twoManDelivery) ? twoManDelivery : undefined}
                onChange={(e: InputSwitchChangeParams) =>
                  setTwoManDelivery(e.target.value)
                }
                className={classNames({
                  sidebar_filter_active: twoManDelivery,
                })}
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab headerTemplate={additionalFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="phone_from_filter">
              {t('Sender phone number')}
            </label>
            <InputText
              id="phone_from_filter"
              value={phoneFrom ?? ''}
              onChange={(e) => setPhoneFrom(e.target.value)}
              className={classNames({
                sidebar_filter_active: phoneFrom,
              })}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="phone_to_filter">
              {t('Recipient phone number')}
            </label>
            <InputText
              id="phone_to_filter"
              value={phoneTo ?? ''}
              onChange={(e) => setPhoneTo(e.target.value)}
              className={classNames({
                sidebar_filter_active: phoneTo,
              })}
            />
          </div>
          {subsidiaries?.length && (
            <div className="sidebar_filter">
              <label htmlFor="branch_filter">{t('Include Subsidiaries')}</label>
              <InputSwitch
                id="branch_filter"
                checked={isBoolean(branch) ? branch : undefined}
                onChange={(e: InputSwitchChangeParams) =>
                  setBranch(e.target.value)
                }
                className={classNames({
                  sidebar_filter_active: branch,
                })}
                inputId="branch"
              />
            </div>
          )}
        </AccordionTab>
      </Accordion>
    ),
    [
      dateFrom,
      dateTo,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      clientOrdererName,
      serial,
      clientFromName,
      reference,
      clientToName,
      status,
      returnDocument,
      packagingId,
      roles,
      codPaymentStatus,
      fragile,
      twoManDelivery,
      phoneFrom,
      phoneTo,
      branch,
      basicFiltersHeaderTemplate,
      orderAttributesFiltersHeaderTemplate,
      additionalFiltersHeaderTemplate,
      orderPackagingOptions,
      orderReturnDocumentOptions,
      paymentStatusOptions,
      rolesOptions,
      statusesOptions,
      subsidiaries?.length,
      isLoadingStatuses,
      errorStatuses,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () =>
      basicFiltersActiveFilterCount +
      orderAttributesFiltersActiveFilterCount +
      additionalFiltersActiveFilterCount,
    [
      basicFiltersActiveFilterCount,
      orderAttributesFiltersActiveFilterCount,
      additionalFiltersActiveFilterCount,
    ]
  );

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
