import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { usePhoneOptionsContext } from '../../../../context/PhoneOptionsContext';
import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import { ErrorResponse } from '../../../../types/api';
import {
  MunicipalityCollection,
  MunicipalityPlaceCollection,
} from '../../../../types/api/municipalities';
import { PlaceStreetCollection } from '../../../../types/api/places';
import { Numeric } from '../../../../types/general';
import { LabelValue } from '../../../../types/options';
import { isStreetFreeInputAllowed } from '../../../../utils/constants/misc';
import { invalidPhoneNumberCharactersRegex } from '../../../../utils/constants/phoneNumbers';
import {
  AllowedErrors,
  tryApiContextValidation,
} from '../../../../utils/helpers/formik';
import {
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../utils/helpers/misc';
import {
  formatMobileNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../utils/helpers/phoneNumbers';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import UnauthPage from '../../../page/UnauthPage';
import {
  FormFields,
  getInitialValues,
  getValidationSchema,
} from './Register.functions';

function Register(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const formRef = useRef<FormikProps<FormFields>>(null);
  const { toastRef } = useContext(ToastContext);

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  const { data: municipalitiesData, isLoading: isMunicipalitiesDataLoading } =
    useAxios<MunicipalityCollection>({
      url: '/municipalities',
      headers: {
        'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
      },
    });
  console.log('municipalitiesData municipalitiesData ', municipalitiesData);
  const {
    data: placesInMunicipalityData,
    reload: reloadPlacesInMunicipalityData,
    isLoading: isPlacesInMunicipalityDataLoading,
  } = useAxios<MunicipalityPlaceCollection>(
    {
      url: `/municipalities/${formRef?.current?.values.municipality}/places`,
      headers: {
        'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
      },
    },
    {
      skipWhen: !formRef?.current?.values.municipality,
    }
  );

  const {
    data: streetsData,
    reload: reloadStreetsData,
    isLoading: isStreetsDataLoading,
  } = useAxios<PlaceStreetCollection>(
    {
      url: `/places/${formRef?.current?.values.place}/streets`,
      headers: {
        'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
      },
    },
    {
      skipWhen: !formRef?.current?.values.place,
    }
  );

  const municipalityOptions = useMemo<LabelValue<number>[]>(
    () =>
      municipalitiesData?.map((municipality) => ({
        value: municipality.id,
        label: municipality.ime,
      })) ?? [],
    [municipalitiesData]
  );

  const placeOptions = useMemo<LabelValue[]>(
    () =>
      placesInMunicipalityData?.map((place) => ({
        value: String(place.id),
        label: place.ime,
        postal_code: place.postenski_broj,
      })) ?? [],
    [placesInMunicipalityData]
  );

  const streetOptions = useMemo<LabelValue[]>(
    () =>
      streetsData?.map((street) => ({
        value: String(street.id),
        label: street.ime,
      })) ?? [],
    [streetsData]
  );

  // Dialog state
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);

  // User registration
  const {
    data: registerClientData,
    error: registerClientError,
    reload: registerClient,
  } = useAxios<any, ErrorResponse>();

  const registerClientDataPrevious = usePrevious(registerClientData);
  const registerClientErrorPrevious = usePrevious(registerClientError);

  useEffect(() => {
    if (
      !registerClientData ||
      registerClientData === registerClientDataPrevious ||
      !toastRef?.current
    ) {
      return;
    }

    successToast(
      toastRef,
      t('Successful User Registration'),
      t('Your account has been registered. Follow the email instructions.')
    );

    setDialogVisibility(true);
  }, [registerClientData, registerClientDataPrevious, t, toastRef]);

  const allowedErrors: AllowedErrors = useMemo(
    () => ({
      ime: {
        already_in_use: t('A client with this name already exists'),
        customName: 'name',
      },
      email: {
        duplicate: t('A client with this email already exists'),
        already_in_use: t('A client with this email already exists'),
      },
      mobilen: {
        already_in_use: t('A client with this mobile number already exists'),
        customName: 'phone',
      },
      danocen_broj: {
        combination_ssn_tax_already_in_use: t(
          'A client with this SSN - Tax No. combination already exists'
        ),
        customName: 'tax_number',
      },
    }),
    [t]
  );

  useEffect(() => {
    if (
      !registerClientError ||
      registerClientError === registerClientErrorPrevious ||
      !toastRef?.current
    ) {
      return;
    }

    const errorDescription =
      registerClientError.response?.data.error_description;

    if (errorDescription) {
      const validated = tryApiContextValidation(
        errorDescription,
        formRef as any,
        allowedErrors
      );

      if (!validated) {
        errorToast(
          toastRef,
          t('User Registration Failed'),
          t('An error occurred while trying to register user.')
        );
      }
    }
  }, [
    allowedErrors,
    registerClientError,
    registerClientErrorPrevious,
    t,
    toastRef,
  ]);

  function handleUserRegisration(values: FormFields) {
    console.log('values ', values);
    let submitData: Record<string, Numeric> = {
      ime: values.name,
      email: values.email,
      mobilen: formatMobileNumber(values.phone, values.phone_region),
      mesto_id: values.place,
    };

    if (isStreetFreeInputAllowed) {
      submitData.adresa = values.address;
    } else {
      submitData.ulica_id = values.street;
      submitData.broj = values.house_number;
      submitData.vlez = values.entrance_number;
      submitData.stan = values.flat_number;
    }

    if (values.legal_entity) {
      submitData['pravno_lice'] = Number(values.legal_entity);
      submitData['danocen_broj'] = values.tax_number;
    }

    registerClient({
      url: '/clients/register',
      method: 'POST',
      headers: {
        'X-Client-Id': process.env.REACT_APP_CLIENT_ID,
      },
      data: submitData,
    });
  }

  const initialValues = useMemo(() => getInitialValues(), []);

  function goToLogin() {
    history.push('/login');
  }

  return (
    <>
      <Dialog
        header={t('Successful User Registration')}
        visible={dialogVisibility}
        onHide={() => {
          setDialogVisibility(false);
        }}
        footer={<Button type="button" label={t('OK')} onClick={goToLogin} />}
      >
        <p>{t('Your account has been sucessfully registered.')}</p>
        <p>
          {t(
            'Your request for registration will be processed soon. For the status of your activation you will be notified by email.'
          )}
        </p>
      </Dialog>

      <UnauthPage
        title={t('Create an Account')}
        description={t('Enter your information to complete the registration.')}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validateOnChange
          validationSchema={getValidationSchema(t)}
          onSubmit={handleUserRegisration}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="p-d-flex p-ai-center p-mb-2">
                <Field
                  type="checkbox"
                  name="legal_entity"
                  inputId="legal_entity"
                  as={Checkbox}
                />
                <label htmlFor="legal_entity">{t('Legal Entity')}</label>
              </div>

              <div className="p-fluid">
                <FieldWithErrors name="name" label={t('Name')}>
                  <Field type="input" name="name" as={InputText} />
                </FieldWithErrors>

                <FieldWithErrors name="email" label={t('Email')}>
                  <Field type="email" name="email" as={InputText} />
                </FieldWithErrors>

                <FieldWithErrors name="phone" label={t('Mobile Number')}>
                  <div className="p-inputgroup">
                    <Field
                      as={Dropdown}
                      className="phone_region"
                      style={{ padding: 0, width: '5rem' }}
                      id="phone_region"
                      name="phone_region"
                      options={languages}
                      itemTemplate={countryOptionTemplate}
                      valueTemplate={selectedCountryTemplate}
                      data-cy="phone_region"
                    />
                    <Field
                      type="input"
                      style={{ width: '100%' }}
                      name="phone"
                      as={InputText}
                      value={formatMobileNumber(
                        values.phone,
                        values.phone_region ||
                          getPhoneorMobileNumberRegion(values.phone)
                      )}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          'phone',
                          e.target.value.replace(
                            invalidPhoneNumberCharactersRegex,
                            ''
                          )
                        )
                      }
                    />
                  </div>
                </FieldWithErrors>

                {values.legal_entity && (
                  <FieldWithErrors name="tax_number" label={t('Tax Number')}>
                    <Field type="input" name="tax_number" as={InputText} />
                  </FieldWithErrors>
                )}

                <FieldWithErrors name="municipality" label={t('Municipality')}>
                  <Field
                    name="municipality"
                    inputId="municipality"
                    as={Dropdown}
                    filter
                    options={municipalityOptions}
                    placeholder={
                      isMunicipalitiesDataLoading
                        ? t('Loading...')
                        : t('Select Municipality')
                    }
                    disabled={isMunicipalitiesDataLoading}
                    onChange={(e: DropdownChangeParams) => {
                      setFieldValue('municipality', e.value);
                      setFieldValue('place', '');
                      setFieldValue('street', '');
                      setFieldValue('house_number', '');

                      reloadPlacesInMunicipalityData({
                        url: `municipalities/${e.value}/places`,
                      });
                    }}
                    className="data-cy-municipality"
                  />
                </FieldWithErrors>

                <FieldWithErrors name="place" label={t('Place')}>
                  <Field
                    type="input"
                    name="place"
                    inputId="place"
                    as={Dropdown}
                    filter
                    options={placeOptions}
                    itemTemplate={placeItemTemplate}
                    valueTemplate={placeValueTemplate}
                    placeholder={
                      isPlacesInMunicipalityDataLoading ? t('Loading...') : ''
                    }
                    onChange={(e: DropdownChangeParams) => {
                      setFieldValue('place', e.value);
                      setFieldValue('street', '');
                      setFieldValue('house_number', '');
                      reloadStreetsData({ url: `places/${e.value}/streets` });
                    }}
                    disabled={
                      !values.municipality || isPlacesInMunicipalityDataLoading
                    }
                    className="data-cy-place"
                  />
                </FieldWithErrors>

                {isStreetFreeInputAllowed ? (
                  <FieldWithErrors
                    name="address"
                    label={
                      isStreetFreeInputAllowed ? t('Address') : t('Street')
                    }
                  >
                    <Field
                      as={InputText}
                      name="address"
                      id="address"
                      disabled={!values.municipality || !values.place}
                      maxLength="256"
                    />
                  </FieldWithErrors>
                ) : (
                  <>
                    <FieldWithErrors name="street" label={t('Street')}>
                      <Field
                        type="input"
                        name="street"
                        inputId="street"
                        as={Dropdown}
                        filter
                        options={streetOptions}
                        disabled={!values.place || isStreetsDataLoading}
                        placeholder={
                          isStreetsDataLoading ? t('Loading...') : ''
                        }
                        className="data-cy-street"
                      />
                    </FieldWithErrors>
                    <div className="p-grid">
                      <div className="p-col">
                        <FieldWithErrors
                          name="house_number"
                          label={t('Number')}
                        >
                          <Field
                            type="input"
                            name="house_number"
                            as={InputText}
                            disabled={!values.street}
                          />
                        </FieldWithErrors>
                      </div>

                      <div className="p-col">
                        <FieldWithErrors
                          name="entrance_number"
                          label={t('Entrance No.')}
                        >
                          <Field
                            type="input"
                            name="entrance_number"
                            as={InputText}
                            disabled={!values.street}
                          />
                        </FieldWithErrors>
                      </div>

                      <div className="p-col">
                        <FieldWithErrors
                          name="flat_number"
                          label={t('Flat No.')}
                        >
                          <Field
                            type="input"
                            name="flat_number"
                            as={InputText}
                            disabled={!values.street}
                          />
                        </FieldWithErrors>
                      </div>
                    </div>
                  </>
                )}

                <p className="p-mt-2 p-mb-0 p-text-secondary p-text-light">
                  {t('Already have an account?')}{' '}
                  <Link to="/login">{t('Login')}</Link>
                </p>

                <Button
                  type="submit"
                  label={t('Register')}
                  className="p-mt-5"
                />
              </div>
            </Form>
          )}
        </Formik>
      </UnauthPage>
    </>
  );
}

export default Register;
