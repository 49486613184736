import { TFunction } from 'react-i18next';

import { ServiceType } from '../../../../../../types/api/orders';

export function getServiceType(t: TFunction, usluga_id: ServiceType) {
  switch (usluga_id) {
    case ServiceType.Postage: {
      return t('Postage');
    }

    case ServiceType.Redemption: {
      return t('Refundable redemption');
    }

    case ServiceType.ReturnDocument: {
      return t('Return document');
    }

    case ServiceType.Insurance: {
      return t('Order insurance');
    }
  }
}
